<template lang="">
  <div class="main-container">
    <app-header />
    <nav-bar />
    <overlay-loader :loading="loading"></overlay-loader>
    <v-alert v-if="alert" class="mt-4" type="error" transition="scale-transition">{{ error }}</v-alert>
    <v-container>
      <v-card>
        <v-card-text>
          <v-row>
            <!-- Main actions -->
            <v-col cols="12" md="4">
              <v-select
                v-model="selectedStudy"
                :items="studies"
                label="Study name"
                item-text="name"
                @input="changedStudy()"
                return-object
                underlined>
              </v-select>
            </v-col>
            <v-col cols="12" md="8" v-if="selectedStudy" class="pt-6 text-right">
              <v-btn
                id="open-folder-matrix-btn"
                class="ml-5"
                @click="openFolderMatrix()"
                outlined
                color="primary"
                rounded>
                  Open folder matrix<v-icon right>mdi-matrix</v-icon>
              </v-btn>
              <v-btn
                id="export-report-btn1"
                class="ml-5"
                @click="exportReport()"
                outlined
                color="primary"
                rounded>
                  Export report<v-icon right>mdi-file-download-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="selectedStudy">
            <!-- Milestones and expected documents accordeon -->
            <v-col cols="12">
              <v-card>
                <v-toolbar class="mb-12" color="primary" light>
                  <v-toolbar-title class="white--text mx-12">
                    <v-card-title class="title_doc">List of milestones</v-card-title>
                  </v-toolbar-title>
                  <slot>
                    <v-btn class="mr-5" fab bottom right absolute @click="showEditMilestonesDialog()"
                    v-if="roleManager.isAnyAdmin()">
                      <v-icon>mdi-flag-triangle</v-icon>
                    </v-btn>
                  </slot>
                  <slot>
                    <v-btn
                      v-if="!showStudyMilestones"
                      style="margin-left: 45%"
                      fab
                      bottom
                      absolute
                      @click="toggleToolbar('studyMilestones')">
                      <v-icon>mdi-arrow-down-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="showStudyMilestones"
                      style="margin-left: 45%;"
                      fab
                      bottom
                      absolute
                      @click="toggleToolbar('studyMilestones')">
                      <v-icon>mdi-arrow-up-circle-outline</v-icon>
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-container v-if="showStudyMilestones">
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :headers="studyMilestoneHeadersFiltered" :items="studyMilestonesItems" dense>
                        <template v-slot:item.is_closed="{ item }">
                          <boolean-label v-bind:active="item.is_closed"/>
                        </template>
                        <template v-slot:item.process="{ item }">
                          <span :class="item.study_req_doc_count_total === item.study_req_exdoc_count_total ?
                          'tfe-green-color': ''">
                            {{ item.study_req_doc_count_total }}/{{ item.study_req_exdoc_count_total }}</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn x-small rounded class="ma-1" outlined :color="item.is_closed ? 'error' : 'primary'"
                          @click="updateStudyMilestoneStatus(item)">
                            {{ item.is_closed ? 'Open': 'Close'}}
                            <v-icon small right>{{ item.is_closed ? 'mdi-lock-open-variant' : 'mdi-lock' }}</v-icon>
                          </v-btn>
                          <v-btn v-if="item.is_closed" x-small rounded class="ma-1" outlined color="primary"
                          @click="exportStudyMilestoneReport(item)">
                          Report<v-icon small right>mdi-file-download-outline</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card>
                <v-toolbar class="mb-12" color="primary" light>
                  <v-toolbar-title class="white--text mx-12">
                    <v-card-title class="title_doc">List of Expected documents</v-card-title>
                  </v-toolbar-title>
                  <slot>
                    <v-btn class="mr-5" fab bottom right absolute @click="exportReportExpectedDocuments()">
                      <v-icon>mdi-file-download-outline</v-icon>
                    </v-btn>
                  </slot>
                  <slot>
                    <v-btn
                      v-if="!showStudyExpectedDocuments"
                      style="margin-left: 45%"
                      fab
                      bottom
                      absolute
                      @click="toggleToolbar('expected_documents')">
                      <v-icon>mdi-arrow-down-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="showStudyExpectedDocuments"
                      style="margin-left: 45%;"
                      fab
                      bottom
                      absolute
                      @click="toggleToolbar('expected_documents')">
                      <v-icon>mdi-arrow-up-circle-outline</v-icon>
                    </v-btn>
                  </slot>
                </v-toolbar>
                <v-container v-if="showStudyExpectedDocuments">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        class="pt-0 mt-0"
                        v-model="studyExpectedDocumentsSearch"
                        append-icon="mdi-magnify"
                        label="Search"
                        hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        class="pt-0 mt-0"
                        v-model="studyExpectedDocumentsFilterOnlyCompleted"
                        :items="studyExpectedDocumentsFilterOnlyCompletedOptions"
                        label="Only completed"
                        underlined
                        item-text="text"
                        item-value="value"></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        dense
                        :headers="studyExpectedDocumentsHeaders"
                        :items="studyExpectedDocuments"
                        :search="studyExpectedDocumentsSearch"
                        :custom-filter="studyExpectedDocumentsCustomFilter">
                        <template v-slot:item.completed="{ item }">
                          <boolean-label v-bind:active="item.completed"/>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Dialogs -->
    <v-dialog v-model="editMilestonesDialogModel" max-width="900" :retain-focus="false">
        <v-card class="dialog-style milestone-dialog">
          <v-card-title>
            <span class="text-h5">Milestones</span>
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :items-per-page="milestonesEdit.length"
              :hide-default-footer="true"
              :headers="milestoneEditDialogHeaders"
              :items="milestonesEdit">
              <template v-slot:item.end_date="{ item }">
                <v-text-field
                  class="pt-0 m-0"
                  v-model="item.end_date"
                  prepend-icon="mdi-calendar"
                  type="date"
                  hide-details
                  single-line
                  underlined>
                </v-text-field>
              </template>
              <template v-slot:item.is_selected="{ item }">
                <v-checkbox v-model="item.is_selected" hide-details></v-checkbox>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined rounded @click="milestonesEdit = []; editMilestonesDialogModel=false"
            color="error">Close</v-btn>
            <v-btn outlined rounded @click="saveMilestones()" color="primary"
            v-if="roleManager.isAnyAdmin()">Save</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="showStudyExpectedDocumentsDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style expected-documents-dialog">
        <v-card-title>
          <span class="text-h5">Expected documents</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :items-per-page="studyExpectedDocumentsEdit.length"
            :hide-default-footer="true"
            :headers="studyExpectedDocumentsDialogHeaders"
            :items="studyExpectedDocumentsEdit"
            :search="studyExpectedDocumentsDialogSearch"
            :custom-filter="studyExpectedDocumentsDialogCustomFilter">
            <template v-slot:top>
              <v-text-field
                class="mt-2 mb-6 ml-2 mr-2"
                v-model="studyExpectedDocumentsDialogSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:item.expected_document_count="{ item }">
              <v-text-field
                class="pb-0 mb-2 pt-0"
                hide-details
                v-model="item.expected_document_count"
                label="Expected document"
                type="text"
                single-line
                underlined
              ></v-text-field>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="showStudyExpectedDocumentsDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded @click="saveStudyExpectedDocuments()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="showStudyMilestoneStatusDialog" max-width="900" :retain-focus="false">
      <v-card class="dialog-style expected-documents-dialog">
        <v-card-title>
          <span class="text-h5">Update milestone status</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <p>
                  Are you sure to <strong>{{updateStudyMilestoneStatusDialogEdit.is_closed ? 'open' : 'close'}}</strong>
                  this milestone <strong>{{updateStudyMilestoneStatusDialogEdit.milestone_name}}</strong>?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="showStudyMilestoneStatusDialog=false" color="error">Cancel</v-btn>
          <v-btn outlined rounded @click="saveStudyMilestoneStatus()" color="primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showMatrixFolderDialog" :retain-focus="false" class="matrix-folder-dialog">
      <matrix-folder
      v-if="selectedStudy"
      :key="matrixFolderDialogKey"
      :zoneItems="zoneItems"
      :sectionItems="sectionItems"
      :artifactItems="artifactItems"
      :studyMilestonesItems="studyMilestonesItems"
      :studyExpectedDocuments="studyExpectedDocuments"
      :studyMilestoneRequirements="studyMilestoneRequirements"
      :studyId="selectedStudyId"
      @finished="finishedMatrixFolder"
      @cancel="showMatrixFolderDialog=false">
      </matrix-folder>
    </v-dialog>
  </div>
</template>
<script>
import BooleanLabel from '@/components/generic/BooleanLabel.vue';
import MatrixFolder from '@/components/milestones/MatrixFolder.vue';
import excelManager from '@/store/excelManager';
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { masterdataServices } from '@/services/masterdataServices';
import { milestonesService } from '@/services/milestonesService';
import { fileServices } from '@/services/fileServices';
import { roleManager } from '@/store/roleManager';

export default {
  name: 'Milestones',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
    BooleanLabel,
    MatrixFolder,
  },
  data() {
    return {
      roleManager,
      loading: false,
      alert: false,
      error: '',
      //
      zoneItems: [],
      sectionItems: [],
      artifactItems: [],
      //
      studies: [],
      selectedStudy: null,
      //
      showStudyMilestones: false,
      studyMilestonesItems: [],
      studyMilestoneHeaders: [
        // { text: 'order', value: 'milestone_matrix_order' },
        { text: 'Milestone name', value: 'milestone_name' },
        { text: 'Level', value: 'milestone_type' },
        { text: 'Country-site', value: 'milestone_type_name' },
        { text: 'End date', value: 'end_date' },
        { text: 'Process', value: 'process' },
        { text: 'Closed', value: 'is_closed' },
        { text: 'Actions', value: 'actions', align: 'right' },
        // { text: 'Completed', value: 'process.completed' },
        // { text: 'Percentage', value: 'process.percentage' },
      ],
      showStudyMilestoneStatusDialog: false,
      updateStudyMilestoneStatusDialogEdit: {},
      //
      milestones: [],
      milestonesEdit: [],
      editMilestonesDialogModel: false,
      milestoneEditDialogHeaders: [
        // { text: 'Milestone order', value: 'matrix_order' },
        { text: 'Milestone name', value: 'name' },
        { text: 'Level', value: 'milestone_type' },
        { text: 'End date', value: 'end_date' },
        { text: 'Is selected', value: 'is_selected' },
      ],
      //
      studyExpectedDocuments: [],
      // studyExpectedDocumentsEdit: [],
      showStudyExpectedDocuments: false,
      // showStudyExpectedDocumentsDialog: false,
      // studyExpectedDocumentsDialogSearch: '',
      // studyExpectedDocumentsDialogHeaders: [
      //   { text: 'Zone', value: 'zone_name' },
      //   { text: 'Section', value: 'section_name' },
      //   { text: 'Artifact', value: 'artifact_name' },
      //   { text: 'Expected documents count', value: 'expected_document_count' },
      // ],
      studyExpectedDocumentsSearch: '',
      studyExpectedDocumentsHeaders: [
        { text: 'Zone', value: 'zone_name' },
        { text: 'Section', value: 'section_name' },
        { text: 'Artifact', value: 'artifact_name' },
        { text: 'Document count', value: 'document_count' },
        { text: 'Expected document count', value: 'expected_document_count' },
        {
          text: 'Completed',
          value: 'completed',
          // eslint-disable-next-line
          filter: value => {
            if (this.studyExpectedDocumentsFilterOnlyCompleted === 1) { // Yes
              return value === true;
            }
            if (this.studyExpectedDocumentsFilterOnlyCompleted === 2) { // No
              return value === false;
            } // All
            return true;
          },
        },
      ],
      studyExpectedDocumentsFilterOnlyCompleted: 0,
      studyExpectedDocumentsFilterOnlyCompletedOptions: [
        { text: 'All', value: 0 },
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 },
      ],
      //
      studyMilestoneRequirements: [],
      //
      showMatrixFolderDialog: false,
      matrixFolderDialogKey: 1,
    };
  },
  mounted() {
    this.getStudies();
    this.getMasterdata();
  },
  computed: {
    selectedStudyId() { return this.selectedStudy ? this.selectedStudy.id : null; },
    studyMilestoneHeadersFiltered() {
      if (roleManager.isAnyAdmin()) return this.studyMilestoneHeaders;
      return this.studyMilestoneHeaders.filter((item) => item.text.toLowerCase() !== 'actions');
    },
  },
  methods: {
    /** Show error alert with a message */
    showErrorAlert(msg) {
      this.alert = true;
      this.loading = false;
      this.error = msg;
      setTimeout(() => {
        this.alert = false;
      }, 5000);
    },
    resetLists() {
      this.milestones = [];
      this.milestonesEdit = [];
      this.studyExpectedDocuments = [];
      this.studyExpectedDocumentsEdit = [];
    },
    // eslint-disable-next-line
    // studyExpectedDocumentsDialogCustomFilter(value, search, item) {
    //   return value != null
    //   && search != null
    //   && typeof value === 'string'
    //   && value.toString().toLowerCase().indexOf(this.studyExpectedDocumentsDialogSearch.toLowerCase()) !== -1;
    // },
    // eslint-disable-next-line
    studyExpectedDocumentsCustomFilter(value, search, item) {
      return value != null
      && search != null
      && typeof value === 'string'
      && value.toString().toLowerCase().indexOf(this.studyExpectedDocumentsSearch.toLowerCase()) !== -1;
    },
    /** Populate and get studies from backend for selector */
    getStudies() {
      this.loading = true;
      fileServices.getStudies()
        .then((response) => { this.studies = response.data; this.loading = false; })
        .catch((error) => { this.loading = false; this.showErrorAlert(error); });
    },
    /** Action called from input on selected study from selector */
    changedStudy() {
      this.getMilestonesAndStudyExpectedDocuments();
    },
    /** Populate and get milestones and expected documents lists */
    getMilestonesAndStudyExpectedDocuments() {
      this.resetLists();
      this.loading = true;
      milestonesService.getMyMilestonesAndStudyExpectedDocuments(this.selectedStudy.id)
        .then((response) => {
          function mo(a, b) { return a.matrix_order < b.matrix_order ? -1 : 1; }
          this.milestones = response.data.milestones.sort(mo);
          this.studyExpectedDocuments = response.data.study_expected_documents;
          this.studyMilestoneRequirements = response.data.study_milestone_requirements;

          function smr(a, b) { return a.milestone_matrix_order < b.milestone_matrix_order ? -1 : 1; }
          this.studyMilestonesItems = response.data.study_milestones.sort(smr);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showErrorAlert(error.response.data.detail);
        });
    },
    getMasterdata() {
      masterdataServices.getMasterdata()
        .then((response) => {
          this.zoneItems = response.data.zones;
          this.sectionItems = response.data.sections;
          this.artifactItems = response.data.artifacts;
        })
        .catch(() => {});
    },
    /** Show milestones dialog */
    showEditMilestonesDialog() {
      this.milestonesEdit = [];
      const smiItems = Array.from(this.studyMilestonesItems);
      const miles = Array.from(this.milestones);
      // this.showStudyExpectedDocumentsDialog = false;
      this.milestonesEdit = miles.map((o) => {
        const smi = smiItems.find((item) => item.milestone_id === o.id);
        /* eslint-disable */
        o.is_selected = ![null, undefined].includes(smi);
        /* eslint-disable */
        o.end_date = smi ? smi.end_date : null;
        return o;
      });
      this.editMilestonesDialogModel = true;
    },
    /** Send editted milestones to backend, and update both lists: milestones and expected docs.
     * Called from save button
     */
    saveMilestones() {
      this.loading = true;
      let selectedMiles = this.milestonesEdit.filter((item) => item.is_selected === true);
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      selectedMiles = selectedMiles.map((m) => ({
        milestone_id: m.id,
        end_date: m.end_date ? new Date(m.end_date).toLocaleString('fr-CA', options) : null,
      }));
      milestonesService.updateMilestones(this.selectedStudy.id, selectedMiles)
        .then(() => {
          this.loading = false;
          this.editMilestonesDialogModel = false;
          this.changedStudy();
        })
        .catch((error) => {
          this.editMilestonesDialogModel = false;
          this.loading = false;
          this.showErrorAlert(error.response.data.detail);
        });
    },
    /** Show expected documents dialog */
    // showEditStudyExpectedDocumentsDialog() {
    //   this.editMilestonesDialogModel = false;
    //   this.showStudyExpectedDocumentsDialog = true;
    //   this.studyExpectedDocumentsEdit = this.studyExpectedDocuments.map((o) => ({ ...o }));
    // },
    /** Send editted expected documents to back, and update both lists: milestones and expected docs.
     * Called from save button
     */
    // saveStudyExpectedDocuments() {
    //   this.loading = true;
    //   const exp = this.studyExpectedDocumentsEdit.map((m) => ({
    //     id: m.id,
    //     expected_document_count: m.expected_document_count,
    //   }));
    //   milestonesService.updateStudyExpectedDocuments(this.selectedStudy.id, exp)
    //     .then(() => {
    //       this.loading = false;
    //       this.showStudyExpectedDocumentsDialog = false;
    //       this.getMilestonesAndStudyExpectedDocuments();
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       this.showStudyExpectedDocumentsDialog = false;
    //       this.showErrorAlert(error);
    //     });
    // },
    updateStudyMilestoneStatus(item) {
      this.updateStudyMilestoneStatusDialogEdit = { ...item };
      this.showStudyMilestoneStatusDialog = true;
    },
    exportStudyMilestoneReport(item) {
      this.loading = true;
      milestonesService.getSingleReport(this.selectedStudy.id, item.id)
        .then((response) => {
          excelManager.exportExcel(response.data, null, `ExportMilestone${item.id}Report`);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showErrorAlert(error.response.data.detail);
        });
    },
    saveStudyMilestoneStatus() {
      const isClosing = !this.updateStudyMilestoneStatusDialogEdit.is_closed;
      const studyMileId = this.updateStudyMilestoneStatusDialogEdit.id;
      milestonesService.updateStudyMilestoneStatus(this.selectedStudy.id, studyMileId, isClosing)
        .then(() => {
          this.loading = false;
          this.showStudyMilestoneStatusDialog = false;
          /* eslint-disable-next-line */
          this.updateStudyMilestoneStatusDialogEdit.is_closed = isClosing;
          const item = this.studyMilestonesItems.find(
            (sm) => sm.id === this.updateStudyMilestoneStatusDialogEdit.id);
          /* eslint-disable-next-line */
          item.is_closed = isClosing;
          this.changedStudy();
        })
        .catch((error) => {
          this.showStudyMilestoneStatusDialog = false;
          this.loading = false;
          this.showErrorAlert(error.response.data.detail);
        });
    },
    /** Toggle accordeon: hide and show specific box. Called from buttons */
    toggleToolbar(element) {
      if (element === 'studyMilestones') {
        this.showStudyMilestones = !this.showStudyMilestones;
      } else if (element === 'expected_documents') {
        this.showStudyExpectedDocuments = !this.showStudyExpectedDocuments;
      }
    },
    /** Call and export report from back to xls. Called from button */
    exportReport() {
      this.loading = true;
      milestonesService.getReport(this.selectedStudy.id)
        .then((response) => {
          excelManager.exportExcel(response.data, null, 'StudyMilestoneReport');
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showErrorAlert(error.response.data.detail);
        });
    },
    exportReportExpectedDocuments() {
      const columns = [
        // eslint-disable-next-line
        { header: 'Zone', key: 'zone_name', width: 30, filterButton: true },
        { header: 'Section', key: 'section_name', width: 30, filterButton: true },
        { header: 'Artifact', key: 'artifact_name', width: 30, filterButton: true },
        { header: 'Doc. count', key: 'document_count', width: 30, filterButton: true },
        { header: 'Exp. Doc. count', key: 'expected_document_count', width: 30, filterButton: true },
        { header: 'Completed', key: 'completed', width: 30, filterButton: true },
      ];
      excelManager.exportExcel(
        this.studyExpectedDocuments, columns, 'ExpectedDocumentsReport', excelManager.TYPE_EXP_DOC,
      );
    },
    openFolderMatrix() {
      /* eslint-disable-next-line */
      this.matrixFolderDialogKey += 1;
      this.showMatrixFolderDialog = true;
    },
    finishedMatrixFolder() {
      this.showMatrixFolderDialog = false;
      this.changedStudy();
    },
  },
};
</script>
<style scoped lang="scss">
  .milestone-dialog {
    .v-input--checkbox {
      margin-top: 0px;
    }
  }
  .expected-documents-dialog {
    .v-input.v-text-field {
      .v-messages {
        background-color: red;
        min-height: 0px;
      }
      .v-text-field__details {
        background-color: red;
        min-height: 0px;
      }
    }
  }
</style>
